import i18n from 'i18next';
import { LOCALE } from '@superset-ui/core';
import { initReactI18next } from 'react-i18next';
// eslint-disable-next-line import/extensions
import dictionary from './localization/dictionary.json';

const getDictionary = (language: LOCALE) => {
  switch (language) {
    case LOCALE.en: {
      const result = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const key in dictionary) {
        const keys = key.split('_');
        if (['one', 'few', 'many', 'other'].includes(keys[keys.length - 1])) {
          result[key] = dictionary[key][1];
        } else {
          result[key] = key;
        }
      }
      return result;
    }
    case LOCALE.ru: {
      const result = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const key in dictionary) {
        result[key] = dictionary[key][0];
      }
      return result;
    }
    default:
      return dictionary;
  }
};

// Инициализация:
i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        'Recently viewed charts, dashboards, and saved queries will appear here':
          'Recently viewed charts, dashboards, and saved queries will appear here',
        ...getDictionary(LOCALE.en),
      },
    },
    ru: {
      translation: {
        'Recently viewed charts, dashboards, and saved queries will appear here':
          'Недавно просмотренные графики, дэшборды и сохраненные запросы',
        ...getDictionary(LOCALE.ru),
      },
    },
  },
  debug: false,
  fallbackLng: 'ru',
  interpolation: { escapeValue: false },
});

export default i18n;
